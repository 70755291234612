import React, { createContext, useContext, useCallback } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useActorRef } from '@xstate/react';
import { OnboardingContext, onboardingMachine, usePersistedOnboardingMachine } from './employer/machines';

// Import all the forms
import { WelcomePage } from './employer/forms/welcome';
import { ConfirmContactForm } from './employer/forms/confirm-contact';
import { NewContactForm } from './employer/forms/add-new-contact';
import { CorporateDocumentsForm } from './employer/forms/corporate-docs';
import { ConfirmEmployerHQController } from './employer/forms/confirm-employer-hq';
import { AddBeneficiaryWorksiteForm } from './employer/forms/add-beneficiary-worksite';
import { EmployerHistoryFormController } from './employer/forms/employer-history';
import { EmployerFundingDocumentsForm } from './employer/forms/employer-funding-docs';
import { ForeignDocumentsForm } from './employer/forms/foreign-docs';
import Conclusion from './employer/forms/conclusion';
import { useStatsigClient } from '@statsig/react-bindings';
import { useContact, usePetitions } from '@utils/hooks';
import { WaitingSplash } from './shared/waiting';
import { Address, PlymouthUser, Team } from '@utils/types';
import { camelCase, kebabCase } from 'change-case';
import { useCompanyMemberByCompanyIdAndUserIdQuery, useGetCompanyQuery } from '@codegen/index';
import { Snapshot } from 'xstate';
import { PetitionFragment, PetitionStatus } from '@codegen/schema';
import { BeneficiaryFlow } from './employer/forms/beneficiary-flow';

// Define the context type
export type EmployerOnboardingContextType = {
  company: Team & Address;
  contact: PlymouthUser;
  members: ({
    isAuthorizedSignatory: boolean | null;
    userByUserId: {
      id: string;
      fullName: string | null;
      email: string | null;
      userDatumById: {
        phoneNumber: string | null;
        role: string | null;
      } | null
    } | null
  } | null)[];
  onSubmit: (data: any) => void;
  onSkip: () => void;
  onBack: () => void;
  companyMember: {
    companyId: string;
    isAuthorizedSignatory: boolean | null;
    userId: string;
  } | null | undefined
  refetchCompany: () => Promise<any>
  petitions: PetitionFragment[]; // Add this line (replace 'any' with the correct type if known)
};

const ROOT_PATH = '/onboarding/employer'
// Create the context
export const EmployerOnboardingContext = createContext<EmployerOnboardingContextType | undefined>(undefined);

// Create a provider component
export function EmployerOnboardingProvider({ children, company, contact, companyMember, members, refetchCompany, petitions }: Omit<EmployerOnboardingContextType, 'onSkip' | 'onBack' | 'onSubmit'> & { children: React.ReactNode }) {

  const initialContact = {
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    phone: contact.phoneNumber,
    isAuthorizedSignatory: companyMember?.isAuthorizedSignatory ? 'yes' : 'no'
  };
  const { getOnboardingState, setOnboardingState } = usePersistedOnboardingMachine(company.value)
  const path = useLocation()
  const currentPath = path.pathname.split('/')?.[3]
  const persistedState = getOnboardingState()

  const resolvedState = onboardingMachine.resolveState(persistedState ?? {
    value: currentPath ? currentPath === 'complete' ? 'final' : camelCase(currentPath) : 'welcome',
    context: {
      currentContact: initialContact,
      companyHQAddress: company.hqAddress
    }
  })
  const actor = useActorRef(onboardingMachine, {
    snapshot: resolvedState
  });

  const [currentPage, setCurrentPage] = React.useState<string>()
  const [_, setCurrentContext] = React.useState<OnboardingContext | undefined>()
  const { client } = useStatsigClient();
  const navigate = useNavigate();
  React.useEffect(() => {
    const subscription = actor.subscribe((snapshot) => {
      setCurrentContext(snapshot.context)
      setCurrentPage(snapshot.value)
    });

    return subscription.unsubscribe;
  }, [actor]);

  const goToPage = (page: string) => {
    const persistedState = actor.getPersistedSnapshot()

    if (persistedState && persistedState.value !== 'welcome') {
      setOnboardingState(persistedState as Snapshot<OnboardingContext>)
    }

    if (page === 'final') {
      navigate(ROOT_PATH + '/complete')
    } else {
      navigate(ROOT_PATH + '/' + kebabCase(page))
    }
  }

  const handleNext = React.useCallback(async (newData?: { data: any, key: keyof OnboardingContext  }) => {
    const { data, key } = newData ?? {}
    
    if (data && key) {
      actor.send({ type: 'SET_DATA', key, value: data });
    }

    actor.send({ type: 'NEXT' });
    const nextState = actor.getSnapshot().value
    goToPage(nextState)
    
  }, [actor])

  const handleSkip = React.useCallback(() => {
    actor.send({ type: 'NEXT' });
    const nextState = actor.getSnapshot().value
    goToPage(nextState)
  }, [actor])

  const onBack = React.useCallback(() => {
    client.logEvent('employer_onboarding', currentPage, {
      action: 'back'
    })
    actor.send({ type: 'BACK' })
    const nextState = actor.getSnapshot().value

    goToPage(nextState)
  }, [actor, currentPage])

  return (
    <EmployerOnboardingContext.Provider value={{
      company, contact, onSubmit: handleNext, onBack, onSkip: handleSkip, companyMember, members, refetchCompany, petitions
    }}>
      {children}
    </EmployerOnboardingContext.Provider>
  );
}

// Custom hook to use the context
export function useEmployerOnboarding() {
  const context = useContext(EmployerOnboardingContext);
  if (context === undefined) {
    throw new Error('useEmployerOnboarding must be used within an EmployerOnboardingProvider');
  }
  return context;
}

export function EmployerOnboarding() {
  const { contact, loading, team } = useContact();
  const { loading: petitionsLoading, data: petitionsData } = usePetitions();
  const { loading: companyMemberLoading, data: companyMemberData } = useCompanyMemberByCompanyIdAndUserIdQuery({
    variables: {
      userId: contact?.id ?? '',
      companyId: team?.value ?? ''
    }
  })

  const { data: companyData, loading: companyDataLoading, refetch: refetchCompany } = useGetCompanyQuery({
    variables: {
      companyId: team?.value ?? '',
      count: 10
    },
    skip: !team?.value
  })

  if (loading || !contact || !companyData || !team || companyMemberLoading || companyDataLoading || petitionsLoading || !petitionsData) {
    return <WaitingSplash />
  }

  const company = {
    ...companyData.companyById,
    ...team,
    street: companyData.companyById?.streetNumberStreetNameBusinessAddress,
    city: companyData.companyById?.cityBusinessAddress,
    state: companyData.companyById?.stateBusinessAddress,
    country: companyData.companyById?.countryBusinessAddress,
    postalCode: companyData.companyById?.zipCodeBusinessAddress,
    isWorksite: companyData.companyById?.isBusinessAddressWorksite,
    unitType: companyData.companyById?.unitTypeBusinessAddress,
    unitNumber: companyData.companyById?.unitNumberBusinessAddress,
  }
  const activePetitions = petitionsData.petitions.filter((petition) => [PetitionStatus.PendingDocs].includes(petition.status) && petition.latestPetition)

  return (
    <EmployerOnboardingProvider company={company} contact={contact} members={companyData?.companyById?.companyMembersByCompanyId?.nodes ?? []} companyMember={companyMemberData?.companyMemberByCompanyIdAndUserId} refetchCompany={refetchCompany} petitions={activePetitions}>
      <Routes>
        <Route path="/confirm-contact" element={<ConfirmContactForm />} />
        <Route path="/add-contact" element={<NewContactForm />} />
        <Route path="/corporate-docs" element={<CorporateDocumentsForm />} />
        <Route path="/confirm-address" element={<ConfirmEmployerHQController />} />
        <Route path="/add-worksite" element={<AddBeneficiaryWorksiteForm />} />
        <Route path="/employer-history" element={<EmployerHistoryFormController />} />
        <Route path="/employer-funding-docs" element={<EmployerFundingDocumentsForm />} />
        <Route path="/foreign-docs" element={<ForeignDocumentsForm />} />
        {['/final', '/complete'].map((path) => (
          <Route key={path} path={path} element={<Conclusion />} />
        ))}
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/" element={<WelcomePage />} />
        <Route path="/beneficiary" element={<BeneficiaryFlow />} />
      </Routes>
    </EmployerOnboardingProvider>
  );
}
