import mime from "mime";
import { UploadStatus, downloadFileFromResponse } from "./files";
import {
  CompanyDocumentType,
  ImmigrationDocumentType,
  IndividualDocumentType,
  VisaClassType,
} from "@codegen/schema";
import { CreatorModule } from "@components/creator/types";
import { graphqlEnumToType, moduleExhibitTypeMap } from "./parsers";
import * as Sentry from '@sentry/react'
import { useLogError } from "./error";
import { PetitionCreatorPayload } from "./types";

const logError = useLogError()
export type APIResponse = {
  success: boolean;
  error?: string;
  data?: Response;
};

const RESEARCH_AGENT_URL = import.meta.env.VITE_RESEARCH_AGENT_HOST ?? "https://research-agent-docker-c7q1.onrender.com";
const LETTERGEN_URL = "https://lettergen-api.onrender.com";

const backendRequest = async (
  route: string,
  method: string,
  headers: Record<string, string>,
  body?: string | FormData
): Promise<APIResponse> => {
  const url = new URL(route, import.meta.env.VITE_INTERNAL_FILE_SERVICE_URL);
  const response = await fetch(url.toString(), {
    method,
    headers,
    body: ["GET", "HEAD"].includes(method) ? undefined : body,
  });

  if (!response.ok) {
    return { success: false, error: response.statusText, data: response };
  }

  return { success: true, data: response };
};

const customFetch = async (
  baseUrl: string,
  route: string,
  method: string,
  headers: Record<string, string>,
  body?: string | FormData
): Promise<APIResponse> => {
  const url = new URL(route, baseUrl);
  try {
    const response = await fetch(url.toString(), {
      method,
      headers,
      body: ["GET", "HEAD"].includes(method) ? undefined : body,
    });

    if (!response.ok) {
      return { success: false, error: response.statusText, data: response };
    }

    return { success: true, data: response };
  } catch (error) {
    return { success: false, error: JSON.stringify(error) };
  }
};

const autogenExhibit = async (params: {
  moduleId: string;
  exhibitType: string;
  orderIndex: number;
  entities: string[];
  token: string;
  parentExhibitId?: string;
  kwargs?: Record<string, string>;
}): Promise<APIResponse> => {
  const {
    moduleId,
    exhibitType,
    orderIndex,
    entities,
    token,
    parentExhibitId,
    kwargs,
  } = params;

  const payload: {
    module_id: number,
    exhibit_type: string,
    order_index: number,
    use_reusable: boolean,
    autogen: boolean,
    visible?: boolean,
    owned_by?: string,
    module_type?: string,
    parent_exhibit_id?: number,
    entities?: number[],
    kwargs?: string,
  } = {
    module_id: parseInt(moduleId),
    exhibit_type: exhibitType,
    order_index: orderIndex,
    use_reusable: [...moduleExhibitTypeMap["awards"]].includes(exhibitType),
    autogen: true,
  }

  if (entities != null && entities.length > 0) {
    payload['entities'] = entities.map(e => parseInt(e))
  }
  if (parentExhibitId != null) {
    payload["parent_exhibit_id"] = parseInt(parentExhibitId)
  }

  if (kwargs != null) {
    payload["kwargs"] = JSON.stringify(kwargs)
  }

  const { success, data, error } = await customFetch(
    RESEARCH_AGENT_URL,
    "/exhibit",
    "POST",
    {
      'Content-Type': 'application/json',
      Authorization: "Bearer " + token,
    },
    JSON.stringify(payload)
  );

  if (!success) {
    console.error("error creating autogen exhibit ", error, {
      JSON: JSON.stringify(data),
    });
    return { success: false, error, data };
  }

  return { success: true, data };
};

const cloneReusableExhibit = async (params: {
  moduleId: string;
  reusableExhibitId: string;
  orderIndex: number;
  token: string;
  reusableExhibitType: string;
}): Promise<APIResponse> => {
  const {
    moduleId,
    reusableExhibitId,
    token,
    reusableExhibitType,
    orderIndex,
  } = params;
  const formData = new FormData();

  formData.append("destination_module_id", moduleId);
  formData.append("reusable_exhibit_id", reusableExhibitId);
  formData.append("reusable_exhibit_type", reusableExhibitType);
  formData.append("order_index", orderIndex.toString());

  const { success, data, error } = await customFetch(
    RESEARCH_AGENT_URL,
    "/reusable/copy",
    "POST",
    {
      Authorization: "Bearer " + token,
    },
    formData
  );

  if (!success) {
    const payload = {
      extra: {
        data: JSON.stringify(data)
      }
    }
    console.error("error creating reusable exhibit ", error, payload);
    Sentry.captureException(error)
    return { success: false, error, data };
  }

  return { success: true, data };
};

export const api = {
  files: {
    uploadFileToExhibit: async (
      file: File,
      exhibitId: string,
      token: string
    ) => {
      const fileName = file.name;
      const mimeType = mime.getType(fileName) ?? "text/plain";

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", fileName);
      formData.append("mimeType", mimeType);
      formData.append("exhibitId", exhibitId as unknown as string);

      const { success, data, error } = await backendRequest(
        "/db/upload/exhibit",
        "POST",
        {
          contentType: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success || data == null) {
        console.error("error uploading file to exhibit ", error, {
          exhibitId,
          JSON: JSON.stringify(data),
        });
        return { success: false, error: UploadStatus.ErrorBackend };
      }

      const res = await data.json();
      return {
        success: true,
        id: res.id,
      };
    },

    uploadImmigrationDoc: async (
      file: File,
      token: string,
      userId: string,
      type: ImmigrationDocumentType
    ) => {
      const fileName = file.name;
      const mimeType = mime.getType(fileName) ?? "text/plain";

      const immDocPostgresTypeMap: Record<ImmigrationDocumentType, string> = {
        I_94: "i-94",
        PASSPORT: "passport",
        VISA: 'visa',
        VISA_STAMP: "visa_stamp",
        APPROVAL_NOTICE: "approval_notice",
        I_20: 'i-20',
        DS_2019: 'ds-2019',
        EAD_CARD: 'ead_card',
        I_94_HISTORY: "i-94-history",
        STUDENT_VISA: "student-visa"
      };

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", fileName);
      formData.append("mimeType", mimeType);
      formData.append("immigrationDocType", immDocPostgresTypeMap[type]);
      formData.append("userId", userId);

      const { success, data, error } = await backendRequest(
        `/db/upload/immigration`,
        "POST",
        {
          contentType: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error uploading immigration doc ", error, {
          userId,
          JSON: JSON.stringify(data),
        });
        return { success: false, error: UploadStatus.ErrorBackend };
      }

      const res = await data?.json();
      return {
        success: true,
        id: res.id,
      };
    },
    uploadIndividualDoc: async (
      file: File,
      token: string,
      userId: string,
      type: IndividualDocumentType
    ) => {
      const fileName = file.name;
      const mimeType = mime.getType(fileName) ?? "text/plain";

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", fileName);
      formData.append("mimeType", mimeType);
      formData.append("documentType", type);
      formData.append("userId", userId);

      const { success, data, error } = await backendRequest(
        `/db/upload/individual`,
        "POST",
        {
          contentType: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        logError("Error uploading individual document", {
          userId,
          JSON: JSON.stringify(data),
          error
        });
        return { success: false, error: UploadStatus.ErrorBackend };
      }

      const res = await data?.json();
      return {
        success: true,
        id: res.id,
        name: fileName,
      };
    },
    uploadCompanyDoc: async (
      file: File,
      token: string,
      companyId: string,
      type: CompanyDocumentType
    ) => {
      const fileName = file.name;
      const mimeType = mime.getType(fileName) ?? "text/plain";

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", fileName);
      formData.append("mimeType", mimeType);
      formData.append("companyDocType", graphqlEnumToType(type));
      formData.append("companyId", companyId);

      const { success, data, error } = await backendRequest(
        `/db/upload/company`,
        "POST",
        {
          contentType: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success || data == null) {
        console.error("error uploading company doc ", error, {
          companyId,
          JSON: JSON.stringify(data),
        });
        return { success: false, error: UploadStatus.ErrorBackend };
      }

      const res = await data.json();
      return {
        success: true,
        id: res.id,
      };
    },
    uploadFormDoc: async (file: File, token: string, formId: string) => {
      const fileName = file.name;
      const mimeType = mime.getType(fileName) ?? "text/plain";

      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", fileName);
      formData.append("mimeType", mimeType);
      formData.append("formId", formId);

      const { success, data, error } = await backendRequest(
        `/db/upload/form`,
        "POST",
        {
          contentType: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success || data == null) {
        console.error("error uploading file to form ", error, {
          formId,
          JSON: JSON.stringify(data),
        });
        return { success: false, error: UploadStatus.ErrorBackend };
      }

      const res = await data.json();
      return {
        success: true,
        id: res.id,
      };
    },

    getFileUrl: async (
      fileId: string,
      token: string
    ): Promise<string | undefined> => {
      const { success, data, error } = await backendRequest(
        `/db/download?id=${fileId}`,
        "GET",
        {
          Authorization: "Bearer " + token,
        }
      );

      if (!success || data == null) {
        console.error("error getting file url ", error, {
          fileId,
          JSON: JSON.stringify(data),
        });

        return;
      }

      const res = await data.json();
      return res.link as string;
    },

    downloadFile: async (fileId: string, token: string) => {
      const { success, data, error } = await backendRequest(
        `/db/download?id=${fileId}`,
        "GET",
        {
          Authorization: "Bearer " + token,
        }
      );

      if (!success || data == null) {
        console.error("error downloading file ", error, {
          fileId,
          JSON: JSON.stringify(data),
        });

        return;
      }

      const json = await data.json();
      const link = json.link as string;

      const downloadResponse = await fetch(link, {
        method: "GET",
      });

      if (!downloadResponse.ok) {
        console.error(
          "error downloading file from presigned url",
          downloadResponse
        );
        return;
      }

      await downloadFileFromResponse(downloadResponse);
    },
  },

  petitionCreator: {
    createPetition: async (
      petition: PetitionCreatorPayload,
      token: string
    ) => {
      const lowerCaseVisaClass = petition.visaClass.toLowerCase();

      const modules = [];

      for (const m of petition.modules) {
        if (m.autogen) {
          const res: Record<string, string | number[] | boolean> = {
            type: m.type,
            autogen: false, //m.autogen,
            entities: m.entities?.map((e) => parseInt(e.id)) ?? [],
            ...(m.autogen ? m.data : {}),
          };

          modules.push(res);
          continue;
        }

        const exhibits = [];

        for (const e of m.exhibits) {
          if (e.type === "patent" && e.autogen) {
            const key = e.data?.key as string;
            const value = e.data?.value as string;
            exhibits.push({
              type: e.type,
              autogen: false,// e.autogen,
              ownedBy: e.ownedBy,
              visible: e.visible,
              [key]: value,
            });

            continue;
          }

          exhibits.push({
            type: e.type,
            autogen: false, // e.autogen,
            ownedBy: e.ownedBy,
            visible: e.visible,
            name: e.name,
            ...(e.autogen ? e.data : {}),
          });
        }

        modules.push({
          type: m.type,
          exhibits,
          autogen: false, //m.autogen,
          entities: m.entities?.map((e) => parseInt(e.id)),
        });
      }

      const body = {
        beneficiaryId: petition.beneficiaryId ? parseInt(petition.beneficiaryId) : null,
        petitionerId: petition.petitionerId ? parseInt(petition.petitionerId) : null,
        visaClass: lowerCaseVisaClass.replaceAll("_", "-"),
        metadata: petition.metadata,
        modules,
      };

      const { success, data, error } = await customFetch(
        RESEARCH_AGENT_URL,
        `/petition`,
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        JSON.stringify(body)
      );

      if (!success || data == null) {
        logError(error, {
          body,
          JSON: JSON.stringify(data),
        });

        return;
      }

      const res = await data.json();
      return res.id as string;
    },
    clonePetition: async (
      petition: {
        id: string
      },
      token: string
    ) => {
      const body = {
        petitionId: parseInt(petition.id),
      };

      const { success, data, error } = await customFetch(
        RESEARCH_AGENT_URL,
        `/clone-petition`,
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        JSON.stringify(body)
      );

      if (!success || data == null) {
        console.error("error cloning petition ", error, {
          body,
          JSON: JSON.stringify(data),
        });

        return;
      }

      const res = await data.json();
      return res.id as string;
    },
    cloneExhibitAsReusable: async (
      exhibitId: string,
      token: string
    ): Promise<APIResponse> => {
      const { success, data, error } = await backendRequest(
        `/db/clone-exhibit`,
        "POST",
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        JSON.stringify({
          exhibitId,
        })
      );

      if (!success || data == null) {
        console.error("error cloning exhibit as reusable ", error, {
          exhibitId,
          JSON: JSON.stringify(data),
        });

        return { success: false, error, data };
      }

      return { success: true, data };
    },
  },
  researchAgent: {
    entityGen: async (
      entityId: string,
      fields: ("long_description" | "short_description" | "bio")[],
      token: string
    ): Promise<Record<string, string> | undefined> => {
      const res = await fetch(
        new URL(`/entitygen`, RESEARCH_AGENT_URL).toString(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify({
            entity_id: entityId,
            fields,
          }),
        }
      );

      if (!res.ok) {
        console.error("error generating entity fields ", JSON.stringify(res));
        return;
      }

      return res.json();
    },
    autogen: {
      exhibits: {
        create: autogenExhibit,
        cloneReusable: cloneReusableExhibit,
        press: async (
          link: string,
          moduleId: string,
          token: string
        ): Promise<APIResponse> => {
          return autogenExhibit({
            moduleId,
            exhibitType: "press-article",
            orderIndex: 0,
            entities: [],
            token,
            kwargs: {
              url: link,
            },
          });
        },
        patent: async (
          key: string,
          value: string,
          moduleId: string,
          token: string,
          parentExhibitId?: string
        ): Promise<APIResponse> => {
          return autogenExhibit({
            moduleId,
            exhibitType: "patent",
            orderIndex: 0,
            entities: [],
            token,
            parentExhibitId,
            kwargs: {
              [key]: value,
            },
          });
        },

        distinguishedReputation: async (
          moduleId: string,
          companyId: string,
          token: string
        ): Promise<APIResponse> => {
          return autogenExhibit({
            moduleId,
            exhibitType: "distinguished-reputation",
            orderIndex: 0,
            entities: [companyId],
            token,
          });
        },

        highRemuneration: async (
          moduleId: string,
          roleEntityId: string,
          locationEntityId: string,
          token: string
        ): Promise<APIResponse> => {
          return autogenExhibit({
            moduleId,
            exhibitType: "compensation-benchmark",
            orderIndex: 0,
            entities: [roleEntityId, locationEntityId],
            token,
          });
        },
      },

      modules: {
        authorship: async (
          petitionId: string,
          moduleName: string,
          googleScholarLink: string,
          numArticles: number,
          token: string
        ): Promise<APIResponse> => {
          const formData = new FormData();
          formData.append("petition_id", petitionId);
          formData.append("name", moduleName);
          formData.append("google_scholar_url", googleScholarLink);
          formData.append("num_articles", numArticles.toString());

          const res = await fetch(
            new URL(`/modules/authorship`, RESEARCH_AGENT_URL).toString(),
            {
              method: "POST",
              body: formData,
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );

          if (!res.ok) {
            console.error(
              "error generating authorship module ",
              JSON.stringify(res)
            );
            return { success: false, error: res.statusText, data: res };
          }

          return { success: true, data: res };
        },

        memoStarter: async (
          petitionId: string,
          token: string
        ): Promise<APIResponse> => {
          const formData = new FormData();
          formData.append("petition_id", petitionId);

          const res = await fetch(
            new URL(`/modules/memo-starter`, RESEARCH_AGENT_URL).toString(),
            {
              method: "POST",
              body: formData,
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );

          if (!res.ok) {
            console.error(
              "error generating memo starter module ",
              JSON.stringify(res)
            );
            return { success: false, error: res.statusText, data: res };
          }

          return { success: true, data: res };
        },
        conclusion: async (
          petitionId: string,
          token: string
        ): Promise<APIResponse> => {
          const formData = new FormData();
          formData.append("petition_id", petitionId);

          const res = await fetch(
            new URL(`/modules/conclusion`, RESEARCH_AGENT_URL).toString(),
            {
              method: "POST",
              body: formData,
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );

          if (!res.ok) {
            console.error(
              "error generating conclusion module ",
              JSON.stringify(res)
            );
            return { success: false, error: res.statusText, data: res };
          }

          return { success: true, data: res };
        },
      },
    },
  },
  lettergen: {
    crLetter: async (
      params: {
        beneficiaryName: string;
        beneficiaryBio: string;
        fieldOfEndeavor: string;
        visaCategory: string;
        companyName: string;
        companyBio: string;
        signatoryName: string;
        signatoryBio: string;
        signatoryTitle: string;
        signatoryEmployer: string;
        howTheyMet: string;
        archetype: string;
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const {
        beneficiaryName,
        beneficiaryBio,
        fieldOfEndeavor,
        visaCategory,
        companyName,
        companyBio,
        signatoryName,
        signatoryBio,
        signatoryTitle,
        signatoryEmployer,
        howTheyMet,
        archetype,
      } = params;

      formData.append("beneficiary_name", beneficiaryName);
      formData.append("beneficiary_bio", beneficiaryBio);
      formData.append("field_of_endeavor", fieldOfEndeavor);
      formData.append("visa_category", visaCategory);
      formData.append("company_name", companyName);
      formData.append("company_bio", companyBio);
      formData.append("signatory_name", signatoryName);
      formData.append("signatory_bio", signatoryBio);
      formData.append("signatory_title", signatoryTitle);
      formData.append("signatory_employer", signatoryEmployer);
      formData.append("how_they_met", howTheyMet);
      formData.append("archetype", archetype);

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/expert-letter/critical-role",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating cr letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
    ocLetter: async (
      params: {
        beneficiaryName: string;
        companyName: string;
        fieldOfEndeavor: string;
        visaCategory: string;
        signatoryName: string;
        signatoryBio: string;
        signatoryTitle: string;
        signatoryEmployer: string;
        howTheyMet: string;
        archetype: string;
        additionalInfo: string;
        docs: FileList | null;
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const {
        beneficiaryName,
        companyName,
        fieldOfEndeavor,
        visaCategory,
        signatoryName,
        signatoryBio,
        signatoryTitle,
        signatoryEmployer,
        howTheyMet,
        archetype,
        additionalInfo,
        docs,
      } = params;

      formData.append("beneficiary_name", beneficiaryName);
      formData.append("company_name", companyName);
      formData.append("field_of_endeavor", fieldOfEndeavor);
      formData.append("visa_category", visaCategory);
      formData.append("signatory_name", signatoryName);
      formData.append("signatory_bio", signatoryBio);
      formData.append("signatory_title", signatoryTitle);
      formData.append("signatory_employer", signatoryEmployer);
      formData.append("how_they_met", howTheyMet);
      formData.append("archetype", archetype);
      formData.append("additional_info", additionalInfo);

      for (const doc of docs ?? []) {
        formData.append("docs", doc);
      }

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/expert-letter/original-contributions",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating cr letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
    crOcLetter: async (
      params: {
        beneficiaryName: string;
        beneficiaryBio: string;
        companyName: string;
        companyBio: string;
        fieldOfEndeavor: string;
        visaCategory: string;
        signatoryName: string;
        signatoryBio: string;
        signatoryTitle: string;
        signatoryEmployer: string;
        howTheyMet: string;
        archetype: string;
        additionalInfo: string;
        docs: FileList | null;
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const {
        beneficiaryName,
        beneficiaryBio,
        companyName,
        companyBio,
        fieldOfEndeavor,
        visaCategory,
        signatoryName,
        signatoryBio,
        signatoryTitle,
        signatoryEmployer,
        howTheyMet,
        archetype,
        additionalInfo,
        docs,
      } = params;

      formData.append("beneficiary_name", beneficiaryName);
      formData.append("beneficiary_bio", beneficiaryBio);
      formData.append("company_name", companyName);
      formData.append("company_bio", companyBio);
      formData.append("field_of_endeavor", fieldOfEndeavor);
      formData.append("visa_category", visaCategory);
      formData.append("signatory_name", signatoryName);
      formData.append("signatory_bio", signatoryBio);
      formData.append("signatory_title", signatoryTitle);
      formData.append("signatory_employer", signatoryEmployer);
      formData.append("how_they_met", howTheyMet);
      formData.append("archetype", archetype);
      formData.append("additional_info", additionalInfo);

      for (const doc of docs ?? []) {
        formData.append("docs", doc);
      }

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/expert-letter/cr-oc-letter",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating cr-oc letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
    aoLetter: async (
      params: {
        beneficiaryName: string;
        beneficiaryBio: string;
        fieldOfEndeavor: string;
        visaCategory: string;
        signatoryName: string;
        signatoryBio: string;
        signatoryTitle: string;
        signatoryEmployer: string;
        howTheyMet: string;
        archetype: string;
        criteria: string[];
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const {
        beneficiaryName,
        beneficiaryBio,
        fieldOfEndeavor,
        visaCategory,
        signatoryName,
        signatoryBio,
        signatoryTitle,
        signatoryEmployer,
        howTheyMet,
        archetype,
        criteria,
      } = params;

      formData.append("beneficiary_name", beneficiaryName);
      formData.append("beneficiary_bio", beneficiaryBio);

      formData.append("field_of_endeavor", fieldOfEndeavor);
      formData.append("visa_category", visaCategory);
      formData.append("signatory_name", signatoryName);
      formData.append("signatory_bio", signatoryBio);
      formData.append("signatory_title", signatoryTitle);
      formData.append("signatory_employer", signatoryEmployer);
      formData.append("how_they_met", howTheyMet);
      formData.append("archetype", archetype);

      for (const c of criteria ?? []) {
        formData.append("criteria", c);
      }

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/ao-letter",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating ao letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
    membershipLetter: async (
      params: {
        beneficiaryName: string;
        beneficiaryBio: string;
        fieldOfEndeavor: string;
        visaCategory: string;
        signatoryName: string;
        signatoryBio: string;
        signatoryTitle: string;
        signatoryEmployer: string;
        howTheyMet: string;
        archetype: string;
        membershipDescription: string;
        membershipSelectionCriteria: string;
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const {
        beneficiaryName,
        beneficiaryBio,
        fieldOfEndeavor,
        visaCategory,
        signatoryName,
        signatoryBio,
        signatoryTitle,
        signatoryEmployer,
        howTheyMet,
        archetype,
        membershipDescription,
        membershipSelectionCriteria,
      } = params;

      formData.append("beneficiary_name", beneficiaryName);
      formData.append("beneficiary_bio", beneficiaryBio);
      formData.append("field_of_endeavor", fieldOfEndeavor);
      formData.append("visa_category", visaCategory);
      formData.append("signatory_name", signatoryName);
      formData.append("signatory_bio", signatoryBio);
      formData.append("signatory_title", signatoryTitle);
      formData.append("signatory_employer", signatoryEmployer);
      formData.append("how_they_met", howTheyMet);
      formData.append("archetype", archetype);
      formData.append("membership_description", membershipDescription);
      formData.append("membership_selectivity", membershipSelectionCriteria);

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/expert-letter/membership",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating membership letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
    awardVcLetter: async (
      params: {
        beneficiaryName: string;
        beneficiaryBio: string;
        companyName: string;
        companyBio: string;
        fieldOfEndeavor: string;
        visaCategory: string;
        signatoryName: string;
        signatoryBio: string;
        signatoryTitle: string;
        signatoryEmployer: string;
        fundingAmount: string;
        fundingYear: string;
        vcSelectionCriteria: string;
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const {
        beneficiaryName,
        beneficiaryBio,
        fieldOfEndeavor,
        visaCategory,
        signatoryName,
        signatoryBio,
        signatoryTitle,
        signatoryEmployer,
        fundingAmount,
        fundingYear,
        vcSelectionCriteria,
        companyBio,
        companyName,
      } = params;

      formData.append("beneficiary_name", beneficiaryName);
      formData.append("beneficiary_bio", beneficiaryBio);
      formData.append("beneficiary_company", companyName);
      formData.append("beneficiary_company_bio", companyBio);
      formData.append("field_of_endeavor", fieldOfEndeavor);
      formData.append("visa_category", visaCategory);
      formData.append("signatory_name", signatoryName);
      formData.append("signatory_bio", signatoryBio);
      formData.append("signatory_title", signatoryTitle);
      formData.append("signatory_employer", signatoryEmployer);
      formData.append("funding_amount", fundingAmount);
      formData.append("funding_year", fundingYear);
      formData.append("vc_selection_criteria", vcSelectionCriteria);

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/expert-letter/award/vc",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating award vc letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
    tnSupportLetter: async (
      params: {
        companyBio: string;
        tnCategory: string;
        resume: File | null;
        offerLetter: File | null;
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const { companyBio, tnCategory, resume, offerLetter } = params;

      formData.append("petitioner_bio", companyBio);
      formData.append("tn_category", tnCategory);

      if (resume) formData.append("resume", resume);

      if (offerLetter) formData.append("job_offer_letter", offerLetter);

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/tn/support-letter",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating tn support letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
    evLetter: async (
      params: {
        beneficiaryName: string;
        beneficiaryTitle: string;
        signatoryName: string;
        signatoryTitle: string;
        company: string;
        salary: string;
        equity: string;
        previousRole: boolean;
        companyBio: string;
      },
      token: string
    ): Promise<APIResponse> => {
      const formData = new FormData();

      const {
        beneficiaryName,
        beneficiaryTitle,
        signatoryName,
        signatoryTitle,
        company,
        salary,
        equity,
        previousRole,
        companyBio,
      } = params;

      formData.append("beneficiary_name", beneficiaryName);
      formData.append("beneficiary_title", beneficiaryTitle);
      formData.append("signatory_name", signatoryName);
      formData.append("signatory_title", signatoryTitle);
      formData.append("signatory_company", company);
      formData.append("salary", salary);
      formData.append("equity", equity);
      formData.append("previous_role", previousRole.toString());
      formData.append("company_details", companyBio);

      const { success, data, error } = await customFetch(
        LETTERGEN_URL,
        "/generate-employment-verification-letter",
        "POST",
        {
          Authorization: "Bearer " + token,
        },
        formData
      );

      if (!success) {
        console.error("error generating ev letter ", error, {
          JSON: JSON.stringify(data),
        });
        return { success: false, error, data };
      }

      return { success: true, data };
    },
  },
  utils: {
    ocr: async (
      fileId: string,
      token: string
    ): Promise<{
      businessName: string;
    } | undefined> => {
      try {
        const { success, data, error } = await backendRequest(
          `/utils/ocr?fileId=${fileId}`,
          "GET",
          {
            Authorization: "Bearer " + token,
          }
        );

        if (!success || data == null) {
          throw error
        }

        const res = await data.json();
        return res
      } catch (exception) {
        logError(exception, {
          fileId
        })
      }
    },
  }
};
